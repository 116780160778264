/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */


/* Core CSS required for Ionic components to work properly */

@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */

@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */

@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";
// Lato Font (300 Light, 400 Regular, 700 Bold, and 900 Black)
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700;900&display=swap");
:root {
    // global theme
    ion-toolbar {
        --background: var(--ion-color-duom-dark-green);
        --min-height: 85px;
        --padding-top: 15px;
    }
    ion-back-button {
        --color: var(--ion-color-duom-white);
        // --icon-margin-start: 15px;
    }
    ion-content {
        --padding-top: 15px;
        --padding-end: 15px;
        --padding-start: 15px;
    }
    ion-list {
        padding-top: 0;
        padding-bottom: 0;
    }
    ion-label {
        font-size: 14px;
        line-height: 22px;
        font-weight: 700;
        color: var(--ion-color-duom-dark-green);
    }
    ion-input,
    ion-select,
    ion-searchbar,
    ion-textarea {
        --background: var(--ion-color-duom-white);
        --color: var(--ion-color-duom-input-text);
        --padding-start: 10px !important;
        --padding-end: 10px !important;
        // redundancies for ion-select, which has no --background & --color props //
        background-color: var(--ion-color-duom-white);
        color: var(--ion-color-duom-input-text);
        border: 1px solid var(--ion-color-duom-input-border);
        border-radius: 5px;
        margin: 10px 0;
    }
    ion-datetime {
        --background: var(--ion-color-duom-white);
        --color: var(--ion-color-duom-input-text);
        --padding-start: 10px !important;
        --padding-end: 10px !important;
        // redundancies for ion-select, which has no --background & --color props //
        background-color: var(--ion-color-duom-white);
        color: var(--ion-color-duom-input-text);
        border: 1px solid var(--ion-color-duom-input-border);
        border-radius: 5px;
        margin: auto;
    }
    ion-searchbar {
        height: 40px;
        --box-shadow: none;
    }
    ion-segment {
        --background: var(--ion-color-duom-white);
        border: 1px solid var(--ion-color-duom-input-border);
        border-radius: 5px;
        height: 40px;
        margin: 10px 0;
    }
    ion-segment-button {
        --background-checked: var(--ion-color-duom-light-green);
        --indicator-color: var(--ion-color-duom-light-green);
        --color-checked: var(--ion-color-duom-white);
    }
    --ion-font-family: "Lato",
    sans-serif;
}

ion-select.department {
    --padding-end: 30px !important;
}

.incident-title {
    // margin-left: 20px;
    margin-left: 10px;
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0.67px;
    color: var(--ion-color-duom-dark-green);
    text-transform: capitalize;
}

.form-title {
    margin-top: 0;
    margin-bottom: 18px;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.67px;
    font-weight: 700;
}

// Cards
.summary-card,
.outage-affected-customers-card,
.summary-time-remaining-card {
    border-radius: 5px;
    box-shadow: 0px 2px 6px 0px rgba(200, 200, 200, 0.5);
}

.summary-card,
.summary-time-remaining-card {
    text-align: center;
    padding: 0 20px 20px 20px;
}

.incident-reports-card {
    display: block;
    .summary-card {
        padding-top: 10px;
        padding-left: 14px;
        padding-right: 14px;
        padding-bottom: 15px;
        ion-grid,
        ion-col {
            padding-bottom: 0;
        }
    }
}

.outage-affected-customers-card {
    padding: 10px 14px;
    margin: 0 24px;
    ion-list {
        padding-left: 20px !important;
    }
}

.guage-card {
    padding: 16px !important;
    margin: 0px !important;
    box-shadow: 0px 2px 6px 0px rgba(200, 200, 200, 0.5);
    ion-card-content {
        padding: 0;
    }
    ion-card-subtitle {
        margin-bottom: 12px;
        color: var(--ion-color-duom-dark-green);
        font-size: 16px;
        line-height: 19px;
        font-weight: 700;
    }
}


/*
  Form Styling
*/

.form-group {
    margin-bottom: 20px;
    ion-label {
        color: var(--ion-color-duom-dark-green);
        font-size: 18px;
        line-height: 22px;
        letter-spacing: 0.6px;
        font-weight: 700;
    }
    ion-segment,
    ion-input,
    ion-textarea {
        margin-top: 7px;
        margin-bottom: 0;
    }
    ion-segment {
        height: 48px;
    }
    ion-segment-button {
        ion-label {
            margin: 0;
        }
    }
}

.duom-header {
    &__icon {
        color: var(--ion-color-duom-white);
        font-size: 35px;
        font-weight: bold;
    }
    &__logo {
        padding-top: 10px;
        height: 65px;
    }
    &__drawerlogo {
        height: 65px;
        width: 84px;
    }
    ion-title {
        text-align: center;
        font-size: 16px;
        font-weight: 700;
        line-height: 19px;
        color: var(--ion-color-duom-white);
        margin-right: 52px; // To make text and logo center
    }
}

ion-icon {
    pointer-events: none;
}


/*
* Modal(s)
*/

.modal-wrapper {
    margin: 12px;
    --width: 100%;
    --max-width: 320px;
    --border-radius: 8px !important;
    --max-height: 75%;
}

.duom-modal {
    --padding-top: 16px !important;
    --padding-start: 16px !important;
    --padding-end: 16px !important;
    --padding-bottom: 16px !important;
    --background: var(--ion-color-duom-white);
    ion-grid,
    ion-col,
    ion-row {
        padding: 0;
    }
    &__title {
        color: var(--ion-color-duom-dark-grey);
        font-size: 18px;
        font-weight: 700;
        letter-spacing: 0.6px;
        line-height: 22px;
    }
    &__confirm-text {
        margin-top: 43px;
        margin-bottom: 50px;
        ion-text {
            color: var(--ion-color-duom-dark-grey);
            font-size: 16px;
            letter-spacing: 0.6px;
            line-height: 22px;
            text-align: center;
        }
    }
    &__close-button {
        --color: var(--ion-color-duom-dark-grey);
        --padding-start: 0;
        --padding-end: 0;
    }
    &__buttons {
        display: flex;
        justify-content: space-between;
        duom-button {
            width: 50%;
            &:first-child {
                margin-right: 5px;
            }
            &:last-child {
                margin-left: 5px;
            }
        }
    }
    &__selects {
        display: flex;
        justify-content: space-between;
        margin-top: 43px;
        margin-bottom: 50px;
        ion-select {
            width: 50%;
            &:first-child {
                margin-right: 5px;
            }
            &:last-child {
                margin-left: 5px;
            }
        }
    }
    &__use-my-location-inner {
        display: flex;
        justify-content: space-between;
        flex-direction: row;
    }
    &__use-my-location-container {
        margin-top: 30px;
        margin-bottom: 40px;
        text-align: center;
    }
    &__use-my-location-button {
        color: var(--ion-color-duom-dark-grey);
        font-size: 18px;
        font-weight: 700;
        line-height: 21px;
        text-transform: initial;
        ion-icon {
            margin-right: 13px;
        }
    }
    &__use-my-location-text {
        display: block;
        margin-top: 35px;
        margin-bottom: 35px;
        color: var(--ion-color-duom-dark-grey);
        font-size: 18px;
        font-weight: 400;
        line-height: 21px;
    }
    &__searchbar {
        --placeholder-color: var(--ion-color-duom-input-text);
        --placeholder-opacity: 1;
        --placeholder-font-weight: 400;
        --border-radius: 5px;
        overflow: hidden;
        height: 40px;
        border: 1px solid var(--ion-color-duom-input-border);
        font-size: 16px;
        text-align: left;
    }
    &__button {
        width: 100%;
        height: 40px;
        font-size: 16px;
        font-weight: 400;
        line-height: 19px;
        text-transform: initial;
        --background: var(--ion-color-duom-dark-green);
        --border-radius: 5px;
        --box-shadow: none;
    }
}

.duom-log-pressence-modal {
    .modal-wrapper {
        --min-height: 260px;
        --height: initial;
        --max-height: initial;
    }
}

.duom-select-your-location-modal {
    .modal-wrapper {
        // --min-height: 370px;
        --min-height: 550px;
        --height: initial;
        --max-height: initial;
    }
}

.duom-add-time-modal {
    .modal-wrapper {
        --min-height: 275px;
        --height: initial;
        --max-height: initial;
    }
}

.duom-image-view-modal {
    .modal-wrapper {
        margin: 12px;
        --width: 100%;
        --max-width: 700px;
        --border-radius: 8px !important;
        --max-height: 85%;
    }
}

.ion-color-duom-light-background {
    background-color: var(--ion-color-duom-light);
    --background: var(--ion-color-duom-light);
}

// Check for desktop size (lg)
.no-scroll {
    --overflow: hidden;
}

@media (max-width: 991px) {
    .lg-desktop {
        display: none !important;
    }
}

// Check for tablet size (md)
@media (min-width: 992px) {
    .md-tablet {
        display: none !important;
    }
}

@media (max-height: 768px) {
    .small-desktop {
        display: none;
    }
}

@media (min-height: 769px) {
    .normal-desktop {
        display: none;
    }
}

@media (max-width: 736px) {
    ion-modal {
        top: 12%;
        max-height: 77vh;
        // z-index: 20001;
        // max-width: 44%;
        // position: absolute;
        // left: 50%;
        // transform: translate(-50%, -50%);
    }
}

.trucks-modal .modal-wrapper {
    height: 300px;
}

.modal-filter-selectable {
    .button-container {
        padding-bottom: 15px;
    }
    ion-content {
        height: 65px;
    }
    ion-toolbar {
        --background: var(--ion-color-duom-white);
        // height: 50px;
    }
    ion-label {
        color: var(--ion-color-duom-dark-blue);
        font-weight: 700;
        font-size: 18px;
    }
    ion-buttons {
        font-size: 34px;
        float: right;
        margin-right: 10px;
    }
    ion-searchbar {
        // margin-top: -30px;
        height: 45px;
        margin-left: 2.5%;
        width: 95%;
    }
    ion-row {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 15px;
        ion-text {
            font-size: 20px;
            font-weight: 700;
            line-height: 24px;
            letter-spacing: 0.67px;
            color: var(--ion-color-duom-dark-grey);
            text-transform: capitalize;
        }
    }
    .ionic-selectable-button {
        text-transform: capitalize;
        font-size: 16px;
        --background: var(--ion-color-duom-dark-green) !important;
        width: 130px;
    }
    .pos-relative {
        position: relative;
        ion-select::part(text) {
            padding-right: 30px;
        }
        ion-datetime::part(text) {
            margin-left: -5px;
        }
        ion-icon {
            color: var(--ion-color-duom-light-grey);
            position: absolute;
            z-index: 2;
            right: 35px;
            top: 45px;
            pointer-events: all;
            cursor: pointer;
        }
        ion-icon.datepicker-icon {
            position: static !important;
        }
    }
}

.popover-menu {
    ion-label {
        padding-left: 16px;
    }
    ion-item {
        font-size: 14px;
        font-weight: 700;
        color: var(--ion-color-duom-grey-2);
        &:hover {
            ion-icon,
            ion-label {
                color: var(--ion-color-duom-dark-green);
            }
        }
    }
}

ionic-selectable.ionic-selectable-has-value {
    .ionic-selectable-inner {
        .ionic-selectable-value {
            padding: 6px 0;
            span {
                display: flex;
                .ionic-selectable-value-item {
                    background: rgb(236 236 236);
                    padding: 6px 8px;
                    margin: 0;
                    margin-right: 10px;
                    border-radius: 5px;
                }
            }
        }
    }
}

ionic-selectable.ionic-selectable-has-placeholder {
    .ionic-selectable-value-item {
        color: var(--placeholder-color, #808080);
    }
}

.date_popover_class {
    --width: 350px !important;
    --height: 255px !important;
}

.ionic-selectable-modal ion-button.ionic-selectable-item-button::part(native) {
    // background: #000;
    border: none;
}

.custom-modal {
    width: 473px;
    height: 460px;
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    top: 55%;
    left: 50%;
    transform: translate(-51%, -49%);
    z-index: 1000;
}

.custom-alert {
    color: #fff;
    /* Replace with your desired color */
}

.custom-alert .alert-header {
    background-color: var(--ion-color-duom-dark-green);
    /* Inherit the background color from the alert */
    color: #fff;
    /* Optional: Set text color for better contrast */
}

.checkBoxEnv {
    margin-left: 15px;
    top: 4px;
}

@media only screen and (min-width: 768px) {
    /* Adjust margin for desktop */
    .checkBoxEnv {
        margin-left: 237px;
    }
}

.alertDanger {
    // background-color: var(--ion-color-danger) !important;
    --ion-color-danger: #b43f3f;
    color: var(--ion-color-danger) !important;
    .alert-head.sc-ion-alert-md {
        background-color: var(--ion-color-danger) !important;
    }
    .alert-head.sc-ion-alert-md+.alert-message.sc-ion-alert-md {
        padding-top: 15px;
        overflow: hidden;
        font-family: fangsong;
        font-size: 17px;
    }
    .alert-wrapper.sc-ion-alert-md {
        height: 220px;
        position: fixed;
    }
    .alert-title.sc-ion-alert-md {
        font-size: 16px;
    }
    button {
        color: var(--ion-color-danger) !important;
    }
    h2 {
        color: ghostwhite !important;
        text-align: left;
        text-transform: uppercase;
    }
}

.alertSuccess {
    --ion-color-success: #35ac1d;
    color: var(--ion-color-success) !important;
    .alert-head.sc-ion-alert-md {
        background-color: var(--ion-color-success) !important;
    }
    .alert-head.sc-ion-alert-md+.alert-message.sc-ion-alert-md {
        padding-top: 20px;
    }
    .alert-wrapper.sc-ion-alert-md {
        height: 180px;
        overflow-y: hidden;
        position: fixed;
    }
    .alert-title.sc-ion-alert-md {
        font-size: 16px;
    }
    button {
        color: var(--ion-color-success) !important;
    }
    h2 {
        color: ghostwhite !important;
        text-align: left;
        text-transform: uppercase;
    }
}

.date.current {
    /* Add any existing styling for the current date */
    /* For example, you can change the color */
    color: var(--ion-color-duom-light-green);
    ;
}

#fake-click {
    display: none;
}

@media only screen and (max-width: 600px) {
    #datetime {
        width: 100%;
        max-width: 300px;
        /* Adjust max-width as needed */
        margin: auto;
        /* Center the component horizontally */
    }
}

@media only screen and (max-width: 600px) {
    .form-group ion-row {
        display: block;
        margin-bottom: 1rem;
        /* Add spacing between rows */
    }
}

.customised-modal {
    --width: 320px;
    --height: 470px;
    --border-radius: 10px;
    --background: white;
}

.customised-modal .modal-wrapper {
    width: var(--width);
    height: var(--height);
    max-width: var(--width);
    max-height: var(--height);
    border-radius: var(--border-radius);
    overflow: hidden;
    background: var(--background);
    margin: auto;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2), 0 12px 40px rgba(0, 0, 0, 0.2);
    padding: 1rem;
}

.start-datetime-modal,
.end-datetime-modal {
    --background: transparent;
}

.info-window-content {
    display: flex;
    font-family: Arial, sans-serif;
    font-size: 14px;
    line-height: 1.6;
}

.info-window-text {
    flex: 1;
    margin-right: 10px;
}

.info-window-heading {
    font-weight: bold;
    text-decoration: underline;
    margin-bottom: 5px;
}

.edit-icon {
    cursor: pointer;
    text-decoration: none;
    color: #2e2d2d;
    position: absolute;
    right: 50px;
    top: 9px;
    size: 100px;
    font-size: x-large;
}

.info-window-slides {
    width: 200px;
    height: 200px;
}

.info-window-image {
    width: 100%;
    height: auto;
}

.swiper-button-next,
.swiper-button-prev {
    color: #000;
    position: absolute;
    top: 50%;
    width: calc(var(--swiper-navigation-size)/ 44 * 27);
    height: var(--swiper-navigation-size);
    margin-top: calc(0px - (var(--swiper-navigation-size) / 2));
    z-index: 10;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

.swiper-button-next {
    right: 10px;
}

.swiper-button-prev {
    left: 10px;
}

.custom-popover .alert-radio-label {
    white-space: normal;
}

.custom-alert .alert-head {
    --ion-color-danger: #b43f3f;
    background-color: var(--ion-color-danger);
}

.custom-alert .alert-title {
    --ion-color-danger: #b43f3f;
    background-color: var(--ion-color-danger);
    color: white;
    ;
}

.custom-alert .alert-message {
    padding-top: 10px !important;
}

.alert-ok-button {
    --ion-color-danger: #b43f3f;
    color: var(--ion-color-danger) !important;
}

.custom-alerts .alert-head {
    --ion-color-medium: #f0870f;
    background-color: var(--ion-color-medium);
}

.custom-alerts .alert-title {
    --ion-color-medium: #f0870f;
    background-color: var(--ion-color-medium);
    color: white;
}

.custom-alerts .alert-button {
    --ion-color-medium: #f0870f;
    color: var(--ion-color-medium);
}

.custom-alert-buttons {
    --ion-color-medium: #f0870f;
    color: var(--ion-color-medium) !important;
}

.custom-alerts .alert-message {
    padding-top: 10px !important;
}